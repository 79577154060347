<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>投放管理</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="add(null, false)">新增投放</el-button>
        </el-col>
      </el-row>
      <!-- 订单列表数据 -->
      <el-table :data="list" style="width: 100%">
        <el-table-column label="商品图片" width="80">
          <template slot-scope="scope">
            <img v-if="scope.row.goodsPic" :src="scope.row.goodsPic.split(',')[0]"
                 style="width: 60px; height: 60px; border: none" />
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称" >
        </el-table-column>
        <el-table-column prop="launchTime" label="投放时间" width="120">
        </el-table-column>
        <el-table-column prop="launchMoney" label="投放成本" width="120">
        </el-table-column>
        <el-table-column label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="primary" @click="add(scope.row, true)">编辑</el-button>
            <el-button type="danger" @click="_delete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>


    <el-dialog title="提示" :visible.sync="dialogVisible" width="40%" >
      <!-- 主要内容 -->
      <el-form label-width="100px">
        
        <el-form-item label="投放商品" >
          <el-input v-model="goodsId" placeholder="投放商品ID" />
        </el-form-item>
        <el-form-item label="投放时间" >
          <el-date-picker
            value-format="yyyy-MM-dd"
            v-model="launchTime"
            placeholder="选择时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="投放成本" >
          <el-input v-model="launchMoney" placeholder="投放成本" />
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
//
<script>
export default {

  data () {
    return {
      list: [],
      dialogVisible: false,
      goodsId: '',
      launchTime: '',
      launchMoney: '',
      editId: null,
      params: {
        beginDate: '',
        endDate: '',
        goodsId: '',
        currentPage: 1,
        pageSize: 50
      },
      goodsList: []
    }
  },
  created () {
    this.GetList();
    this.$api.GetGoodsListToPage({currentPage: 1, pageSize: 200,}).then((res) => {
        this.goodsList = res.data.records
    })
  },
  methods: {
    GetList () {
      this.$api.GetGoodsLaunchList(this.params).then((res) => {
        if (res.code !== 200) this.$message.error('请求失败')
        this.list = res.data
      })
    },
    _delete(id){
      this.$confirm('确定删除吗？', '操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warn',
        callback: action => {
          if (action === 'confirm') {
            this.$api.DeleteGoodsLaunch({id: id}).then((res) => {
              if (res.code !== 200) {
                return this.$message.error('操作失败:' + (res.message || ''))
              }
              this.$message.success('删除成功')
              this.GetList();
            })
          }
        }
      })
    },
    // 点击显示出编辑商品对话框
    add (row, isEdit) {
      this.editId = null;
      this.goodsId = '';
      this.launchTime = '';
      this.launchMoney = '';

      if(isEdit){
        this.editId = row.id;
        this.goodsId = row.goodsId;
        this.launchTime = row.launchTime;
        this.launchMoney = row.launchMoney;
      }
      this.dialogVisible = true
    },

    // 编辑商品
    save () {
      if(!this.goodsId) return this.$message.error('请选择投放商品');
      if(!this.launchTime) return this.$message.error('请选择投放时间');
      if(!this.launchMoney) return this.$message.error('请输入投放成本');
      let promise = null;
      const params = {
        goodsId: this.goodsId,
        launchTime: this.launchTime,
        launchMoney: this.launchMoney,
      }
      if(this.editId){
        params.id = this.editId;
        promise = this.$api.UpdateGoodsLaunch(params)
      }else{
        promise = this.$api.AddGoodsLaunch(params)
      }
      promise.then((res) => {
        if (res.code !== 200) {
          return this.$message.error('请求失败')
        }
        this.$message.success('保存成功')
        this.dialogVisible = false;
        this.GetList();
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
.el-button {
  padding: 10px;
}

.search-input {
  width:150px;
    margin-right: 5px;
}
.search-select{
  width:140px;
  margin-right: 5px;
}
.search-date{
  width:135px;
}
</style>
